import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';

import { Connect } from "@stacks/connect-react";

import { userSession } from "../routes/home/wallet";

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import WhitePaper from "../routes/Whitepaper";
import Chat from "../routes/chat";
const App = () => (
	<div  style={{backgroundColor:"black",minHeight:1000,minWidth:333}}>
		<main style={{backgroundColor:"black"}}>
		<Connect
      authOptions={{
        appDetails: {
      name: "Medieval Hackers",
      icon: "https://medieval-hackers.pages.dev/assets/collection1.png",
        },
        redirectTo: "/",
        onFinish: () => {
          window.location.reload();
        },
        userSession,
      }}
    >
			<Router>
				<Home path="/" />
				<WhitePaper path="/whitepaper" />
			</Router>
			</Connect>
		</main>
	</div>
);

export default App;
